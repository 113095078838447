import payload_plugin_BF3tXFlRX7 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_zFPpBfF0VT from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_FZZ9t2o294 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_L2SahPwtcI from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_y5COZ9hGJB from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.27.0_vite@5.4.11_@types+node@20.5.1_sass@1.81_rlp7tc2iimrbbmr3npti7cg6sm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_fdmXKtkmJ0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_N0Z8JyXD3s from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OjmyNfCC0l from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QFMPIylUGG from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_gI7qOuB1xF from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.27.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_km7GH7Vjeh from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.5.1_eslint@9.11.1_jiti@2.4.0__ioredis@5.4._7jlu677pwb55sfjr3hqfx5c6ru/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  payload_plugin_BF3tXFlRX7,
  revive_payload_client_zFPpBfF0VT,
  unhead_FZZ9t2o294,
  router_L2SahPwtcI,
  _0_siteConfig_y5COZ9hGJB,
  payload_client_fdmXKtkmJ0,
  navigation_repaint_client_N0Z8JyXD3s,
  check_outdated_build_client_OjmyNfCC0l,
  chunk_reload_client_QFMPIylUGG,
  plugin_vue3_gI7qOuB1xF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_km7GH7Vjeh
]